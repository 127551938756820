import "./App.css";
import Dropzone from "react-dropzone";

const uploadFile = async (file) => {
  const url =
    "https://0d8597271a4e16d40bb709728411dfd0.r2.cloudflarestorage.com/demo/resume.txt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=f73252a1903e0213fcc0645a4db22f30%2F20221014%2Fauto%2Fs3%2Faws4_request&X-Amz-Date=20221014T123721Z&X-Amz-Expires=86400&X-Amz-Signature=fb31b1746300323b0764c1c3f904bdcd02f216324820d78a6d4cde7be15ec544&X-Amz-SignedHeaders=host";
  await fetch(url, {
    method: "PUT",
    body: file,
  });
};

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Dropzone onDrop={(acceptedFiles) => uploadFile(acceptedFiles[0])}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Click to upload your resume</p>
              </div>
            </section>
          )}
        </Dropzone>
      </header>
    </div>
  );
}

export default App;
